import { NextPage } from "next";
import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

interface Props {
  children: any;
}

const HeaderFooter: NextPage<Props> = ({ children }) => {
  return (
    <>
      <div className="bg-white">
        <Navbar />
        {children}
        <Footer />
      </div>
    </>
  );
}

export default HeaderFooter;