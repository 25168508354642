import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import Image from 'next/image'

import logo from '../../assets/images/logo.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'

const navbarItems = [
  {
    id: 1,
    label: 'Home',
    href: '/',
  },
  {
    id: 2,
    label: 'Our Services',
    href: '/services',
  },
  {
    id: 3,
    label: 'Career',
    href: '/career',
  },
  {
    id: 4,
    label: 'About',
    href: '/about',
  },
  {
    id: 5,
    label: 'Blogs',
    href: '/blogs',
  },
  {
    id: 6,
    label: 'FAQs',
    href: '/faqs',
  }
]

export default function Navbar() {
  const router = useRouter();

  const goToContactPage = () => {
    router.push('/contact');
  }

  const activePath = '/' + router.pathname.split('/')[1];

  return (
    <>
      <Popover className="relative bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-3 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link href="/" passHref>
                <a>
                  <span className="sr-only">Virtual Gravity</span>
                  <Image 
                    height={50}
                    width={120}
                    className="w-auto"
                    src={logo}
                    alt=""
                  />
                </a>
              </Link>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden lg:flex space-x-10">
              {
                navbarItems.map(({ id, label, href }) => (
                  <Link href={href} key={id}>
                    <a href="#" className={activePath === href ? 'text-sm font-montserratB text-gray-900 uppercase underline underline-offset-8 decoration-secondary-500 decoration-3' : 'text-sm font-montserratB text-primary-500 hover:text-gray-900 uppercase hover:underline hover:underline-offset-8 hover:decoration-secondary-500 hover:decoration-3'}>
                      {label}
                    </a>
                  </Link>
                ))
              }
            </Popover.Group>
            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">

                <button
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-12 py-3 border border-transparent rounded-full shadow-sm text-sm font-montserratB text-white bg-btn-gradient uppercase"
                  onClick={() => { goToContactPage() }}
                  style={{fontSize: '14px'}}
                >
                  Contact us
                </button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-secondary-500">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Link href="/" passHref>
                      <a>
                        <span className="sr-only">Virtual Gravity</span>
                        <Image 
                          height={50}
                          width={120}
                          className="w-auto"
                          src={logo}
                          alt=""
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                  {
                    navbarItems.map(({ id, label, href }) => (
                      <Link href={href} key={id}>
                         <a href="#" className={activePath === href ? 'text-sm font-montserratB text-gray-900 uppercase underline underline-offset-8 decoration-secondary-500 decoration-3' : 'text-sm font-montserratB text-primary-500 hover:text-gray-900 uppercase hover:underline hover:underline-offset-8 hover:decoration-secondary-500 hover:decoration-3'}>
                          {label}
                        </a>
                      </Link>
                    ))
                  }
                </div>
                <div>
                  <button
                    className="w-full flex items-center justify-center whitespace-nowrap px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-montserratB text-white bg-btn-gradient uppercase"
                    onClick={() => { goToContactPage() }}
                  >
                    Contact us
                  </button>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  )
}
