import { PhoneIcon, MailIcon, LocationMarkerIcon, ChevronRightIcon, UserCircleIcon } from "@heroicons/react/outline";
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

export default function NewsLetterForm() {

    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [ loading, setLoading ] = useState(false);

    const onSubmit = async (data: any) => {
        setLoading(true);
        const _window: any = window;
        const { grecaptcha } = _window;
        grecaptcha.ready(() => {
            grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(async (token: string) => {
                try {
                    data.g_recaptcha_token = token;
                    const result = await axios.post(`${process.env.NEXT_PUBLIC_ADMIN_URL}/api/news-letter-subscribers`, {
                        data: data
                    });

                    if (result.status === 200) {
                        toast.success('Subscribed successfully.');
                        handleResetForm();
                    } else {
                        toast.error('Something went wrong. Please try again later.');
                    }
                    setLoading(false);
                } catch (e: any) {
                    console.log('Error', e);
                    if (e?.response?.data?.error?.details?.error == 'google-recaptcha-error') {
                        toast.error(e?.response?.data?.error?.message);
                    }else if (e?.response?.data?.error?.name === 'ValidationError') {
                        toast('The email provided has been already subscribed.');
                    } else {
                        toast.error('Something went wrong. Please try again later.');
                    }
                    setLoading(false);
                }
            })
        });
    }

    const handleResetForm = () => {
        reset({
            email: ''
        });
    }

    return (
        <>
            <div className="md:grid md:grid-cols-1 md:gap-8">
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
                <div className="mt-12 md:mt-0">
                    <h4 className="text-base font-montserratB text-gray-400 text-h3 uppercase">Subscribe to our newsletter</h4>
                    <form className="mt-4 sm:max-w-xs" action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                        <fieldset className="w-full">
                            <label htmlFor="language" className="sr-only">
                                Language
                            </label>
                            <div className="relative">
                                <div className="mt-3">
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        id="email-address"
                                        autoComplete="email"
                                        required
                                        className="appearance-none min-w-0 w-full bg-[#3e4357] py-2 px-4 text-base text-white placeholder-gray-500 focus:outline-none sm:max-w-xs rounded-full h-12"
                                        placeholder="Enter your email"
                                        {...register("email", { required: true })}
                                        aria-invalid={errors.email ? "true" : "false"}
                                        disabled={loading}
                                    />
                                    {errors.email?.type === 'required' && <p className="formError">Email is required</p>}
                                </div>

                                <div className="mt-3">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="w-full inline-flex items-center px-4 py-2 border-2 border-white shadow-xs text-base text-h3 font-normal text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 rounded-full justify-center uppercase font-montserratB"
                                    >
                                        {loading ? 'Subscribing...' : 'Subscribe'}
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </>
    );
}
